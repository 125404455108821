import {useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import axios from "axios";

const AuthorizeCallbackComponent = () => {
    let [searchParams] = useSearchParams();
    let apiUrl = process.env.REACT_APP_API_PRINTERVAL_URL;

    useEffect(() => {
        let code = searchParams.get('code');
        if (code) {
            axios.get(`/api/callback?code=${code}`)
                .then(function (response) {
                    if (response.data.status === 'successful') {
                        window.location.href = apiUrl;
                    } else {
                        return (
                            <>Authorize failed</>
                        )
                    }
                })
        } else {
            return (
                <>Authorize failed</>
            )
        }
    }, [searchParams, apiUrl]);
}

export default AuthorizeCallbackComponent