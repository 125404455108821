import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";

const AuthorizeComponent = () => {
    let urlAuthorize = process.env.REACT_APP_URL_AUTHORIZATION_PRINTERVAL;
    let [searchParams] = useSearchParams();
    useEffect(() => {
        window.location.href = urlAuthorize;
    }, [searchParams, urlAuthorize]);

    return (
        <div>Redirecting</div>
    )
}

export default AuthorizeComponent